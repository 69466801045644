import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=7d343606&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=7d343606&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/builds/buro26/projecten/buro26.digital/buro26.digital/components/Logo.vue').default,Header: require('/builds/buro26/projecten/buro26.digital/buro26.digital/components/Header.vue').default})
