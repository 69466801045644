var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page",class:_vm.Page.Slug},[(_vm.Page.ContentAbove)?_c('div',{attrs:{"id":"ContentAbove"}},_vm._l((_vm.Page.ContentAbove),function(ComponentContentAbove){return _c('div',{key:'ComponentContentAbove' + ComponentContentAbove.id},[(ComponentContentAbove.__typename === 'ComponentModuleVisual')?_c('div',{class:ComponentContentAbove.__typename},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"10","offset":"1"}},[_c('ZoneComponent',{attrs:{"component":ComponentContentAbove}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),(ComponentContentAbove.__typename === 'ComponentModuleContent')?_c('div',{class:ComponentContentAbove.__typename},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ZoneComponent',{attrs:{"component":ComponentContentAbove}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),(ComponentContentAbove.__typename === 'ComponentModuleCollectie')?_c('div',{class:ComponentContentAbove.__typename},[_c('ZoneComponent',{attrs:{"component":ComponentContentAbove}})],1):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.Page.Content)?_c('div',{attrs:{"id":"Content"}},_vm._l((_vm.Page.Content),function(ComponentContent){return _c('b-container',{key:'ComponentContent' + ComponentContent.id + Math.floor(Math.random() * 100),class:ComponentContent.__typename,attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ZoneComponent',{attrs:{"component":ComponentContent}})],1)],1)],1)}),1):_vm._e(),_vm._v(" "),(_vm.Page.Slug === 'contact')?_c('div',[(_vm.Page.ContentLeft || _vm.Page.ContentRight)?_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[(_vm.Page.ContentLeft)?_c('b-col',{attrs:{"xl":"10 offset-xl-3","id":"content-block"}},[_vm._l((_vm.Page.ContentLeft),function(ComponentContentLeft){return _c('div',{key:'ComponentContentLeft' +
                  ComponentContentLeft.id +
                  Math.floor(Math.random() * 100),class:ComponentContentLeft.__typename},[_c('ZoneComponent',{attrs:{"component":ComponentContentLeft}})],1)}),_vm._v(" "),_vm._l((_vm.Page.ContentRight),function(ComponentContentRight){return _c('div',{key:'ComponentContentRight' +
                  ComponentContentRight.id +
                  Math.floor(Math.random() * 100),class:ComponentContentRight.__typename},[_c('ZoneComponent',{attrs:{"component":ComponentContentRight}})],1)})],2):_vm._e()],1)],1)],1)],1):_vm._e()],1):_c('div',[(_vm.Page.ContentLeft || _vm.Page.ContentRight)?_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[(_vm.Page.ContentLeft)?_c('b-col',{attrs:{"lg":"8","id":"ContentLeft"}},_vm._l((_vm.Page.ContentLeft),function(ComponentContentLeft){return _c('div',{key:'ComponentContentLeft' +
              ComponentContentLeft.id +
              Math.floor(Math.random() * 100),class:ComponentContentLeft.__typename},[_c('ZoneComponent',{attrs:{"component":ComponentContentLeft}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.Page.ContentRight)?_c('b-col',{attrs:{"lg":"4","id":"ContentRight"}},_vm._l((_vm.Page.ContentRight),function(ComponentContentRight){return _c('div',{key:'ComponentContentRight' +
              ComponentContentRight.id +
              Math.floor(Math.random() * 100),class:ComponentContentRight.__typename},[_c('ZoneComponent',{attrs:{"component":ComponentContentRight}})],1)}),0):_vm._e()],1)],1):_vm._e()],1),_vm._v(" "),(_vm.Page.ContentRight)?_c('div',{attrs:{"id":"ContentBelow"}},_vm._l((_vm.Page.ContentBelow),function(ComponentContentBelow){return _c('div',{key:'ComponentContentBelow' +
        ComponentContentBelow.id +
        Math.floor(Math.random() * 100),class:ComponentContentBelow.__typename},[_c('b-container',{attrs:{"fluid":"xl"}},[(ComponentContentBelow.__typename === 'ComponentModuleVisual')?_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('ZoneComponent',{attrs:{"component":ComponentContentBelow}})],1)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ZoneComponent',{attrs:{"component":ComponentContentBelow}})],1)],1)],1)],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }