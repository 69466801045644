
import Vue from "vue"
import ShareButton from "~/components/Buttons/ShareButton.vue";

export default Vue.extend({
  name: "Socials",
  components: {
    ShareButton,
  },
  props: {
    showTitle: Boolean,
    title: String,
    iconSize: String,
    share: Boolean,
    direction: String,
    useLabels: Boolean,
    shareText: String,
    shareUri: String,
  },
});
