import Vue from "vue";

var months = [
  "januari",
  "februari",
  "maart",
  "april",
  "mei",
  "juni",
  "juli",
  "augustus",
  "september",
  "oktober",
  "november",
  "december"
];

Vue.filter("formatDate", (dateStr: string) => {
  //Intl.DateTimeFormat("nl-NL", { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(dateStr))
  //werkt niet op netlify vanwege ontbreken locale nl
  var date = new Date(dateStr);
  var dateFormatted = date.getDate()+" "+months[date.getMonth()]+" "+date.getFullYear(); 
  return dateFormatted;
  }
);