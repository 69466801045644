
import { PropOptions } from "vue"; // @ts-ignore
import Button from "~/components/Buttons/Button.vue"; // @ts-ignore
import ContentCard from "~/components/data-display/ContentCard.vue"; // @ts-ignore
import tag from "~/components/data-display/tag.vue"; // @ts-ignore
import tags from "~/components/data-display/tags.vue"; // @ts-ignoreclass="caption-items"
import { getContentID } from "~/plugins/router";
import { Strapi } from "~/types/typings.d"; // @ts-ignore
// @ts-ignore
import OgImage from "~/assets/img/OG-1200x630.png";
// @ts-ignore
import markdownIt from 'markdown-it';

export default {
  // @ts-ignore
  head() {
    return {
      // @ts-ignore
     title: this.Case?.Title?.replace(/\*\*/g, ""),
      link: [
        {
          rel: "canonical",
          // @ts-ignore
          href: "https://www.buro26.digital" + (this.$route.path.endsWith('/') ? this.$route.path : this.$route.path + '/'),
        },
      ],
      meta: [
        {
          hid: "description",
          name: "description",
          // @ts-ignore
          content: this.metaDescription?.replace(/<a\b[^>]*>(.*?)<\/a>/gi, ""),
        },
        {
          hid: "og:url",
          property: "og:url",
          content: "https://www.buro26.digital",
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          hid: "og:title",
          property: "og:title",
          // @ts-ignore
          content: this.Case?.Title?.replace(/\*\*/g, ""),
        },
        {
          hid: "og:description",
          property: "og:description",
            // @ts-ignore
          content:  this.metaDescription?.replace(/<a\b[^>]*>(.*?)<\/a>/gi, ""),
        },
        {
          hid: "og:image",
          property: "og:image",
          content: OgImage,
        }
      ],
    };
  },
  props: {
    $strapi: {
      type: Object,
      required: false,
    } as PropOptions<Strapi>,
  },
  components: {
    Quote: () => import("~/components/Typography/Quote.vue"),
    Button,
    tag,
    tags,
    ContentCard,
  },
  methods: {
    scrollToClientInfo() {
      // @ts-ignore
      this.$refs["client-info"].scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  computed: {
    renderTitle(): string {
      const md = markdownIt({ html: true, breaks: true });
      // Parse markdown and render without <p> tags
       // @ts-ignore
      return md.renderInline(this?.Case?.Title);
    },
  },
  async asyncData({ route, $strapi }: { route: any; $strapi: Strapi }) {

  const slug = await getContentID(route);

  const response = await $strapi.graphql({
      query:
        `
        query{
          cases (filters: { Slug: { eq: "` + slug + `"} } ) {
            data {
              id
              attributes {
                Title
                Url {
                  Title
                  Url
                  External
                }
                Intro {
                  Text
                  Color
                }
                Client {
                  data {
                    attributes {
                      Title
                      Logo {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                      Text
                    }
                  }
                }
                Reason
                Solution
                Result
                Quote {
                  Title
                  Function
                  Text
                }
                ImageTop {
                  data {
                    attributes {
                      url
                      caption
                    }
                  }
                }
                ImageLeft {
                  data {
                    attributes {
                      url
                      caption
                    }
                  }
                }
                ImageMiddle {
                  data {
                    attributes {
                      url
                      caption
                    }
                  }
                }
                ImageRight {
                  data {
                    attributes {
                      url
                      caption
                    }
                  }
                }
                ImageFull {
                  data {
                    attributes {
                      url
                      caption
                    }
                  }
                }
                Technieken {
                  Techniek
                }
              }
            }
          }
        }
      `,
    });

    const Case = await response.cases.data[0].attributes;

    //external link, add target=_blank
    Case.Reason = externalLink(Case?.Reason);
    Case.Solution = externalLink(Case?.Solution);
    Case.Result = externalLink(Case?.Result);

    return { Case };
  },
};
function externalLink(text: String) {
  //external link, add target=_blank
  const links = text.match(/ \[([^\[]+)\](\(http.*\))/gm);
  if (links !== null && links.length > 0) {
    links.map((link: string) => {
      let href = link.match(/\((.*?)\)/);
      let title = link.match(/\[(.*?)\]/);
      if (href !== null && title !== null) {
        text = text.replace(
          link,
          ' <a href="' + href[1] + '" target="_blank">' + title[1] + "</a>"
        );
      }
    });
  }
  return text;
}
