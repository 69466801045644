export default function ({ $axios, redirect, $config: { apiToken } }) {

  $axios.setToken(apiToken, 'Basic');

  $axios.onRequest(config => {
    console.log("$axios.onRequest: " + config.url)
  })

  $axios.onError(error => {
    console.log('Error:', error.response)
  })
}