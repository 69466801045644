
import Vue from "vue";

export default Vue.extend({
  name: "ContentCard",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    badge: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    imageLocation: {
      type: String,
      required: false,
      default: "right",
    },
  },
  data() {
    return {
      className: `content-card ${this.$props.classes}`,
    };
  },
});
