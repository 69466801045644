import StaticRoutes from '../static/routes.json';

export function getStaticRoutes() {
  return ['/'];
}

export function getContentID(route) {
  if (route.params !== undefined && route.params.id !== undefined) {
    return route.params.id;
  }
  if ((route.params !== undefined) && (route.params.slug !== undefined)) {
    return route.params.slug;
  }

  let id = null;
  StaticRoutes.map(el => {
    if (el.name === route.name) {
      id = el.id !== undefined ? el.id : null;
    }
  });

  return id;
}

export function getRouteByTypeNameAndSlug(typeName, slug) {
  switch (typeName) {
    case "Blog":
      return {name: "/blog-detail", params: {id: slug}}
    case "Page":
      return {name: 'page', params: {id: slug}}
    case "Case":
      return {name: '/cases-detail', params: {id: slug}}
    case "Skill":
      return {name: '/expertises-detail', params: {id: slug}}
  }

  return slug
}
