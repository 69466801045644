
import Vue, { PropOptions } from "vue" // @ts-ignore

export default Vue.extend({
  props: {
    component: {
      type: Object,
      required: true,
    } as PropOptions<any>,
  },
  components: {
    Block: () => import("~/components/zone/Block.vue"),
    Visual: () => import("~/components/zone/Visual.vue"),
    VisualList: () => import("~/components/zone/VisualList.vue"),
    Content: () => import("~/components/zone/Content.vue"),
    Collection: () => import("~/components/zone/Collection.vue"),
  },
});
