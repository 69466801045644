
import Vue from 'vue'

export enum ButtonVariants {
  DEFAULT = 'default',
  PREVIOUS = 'previous',
}

export default Vue.extend({
  name: "btn",
  props: {
    variant: {
      type: String,
      required: false,
      default: ButtonVariants.DEFAULT,
    },
    url: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      classNames: `btn btn-${this.$props.variant} ${this.$props.className || ''}`
    }
  },
})
