
import { defineComponent } from "@nuxtjs/composition-api";
import Button from "~/components/Buttons/Button.vue"; // @ts-ignore
import { getContentID } from "~/plugins/router"; // @ts-ignore
// @ts-ignore
import OgImage from "~/assets/img/OG-1200x630.png";
// @ts-ignore
import markdownIt from 'markdown-it';

export default defineComponent({
  head() {
    return {
      // @ts-ignore
      title: this.Item.Title.replace(/\*\*/g, ""),
      link: [
        {
          rel: "canonical",
          href: "https://www.buro26.digital" + (this.$route.path.endsWith('/') ? this.$route.path : this.$route.path + '/'),
        },
      ],
      meta: [
        {
          hid: "description",
          name: "description",
          // @ts-ignore
          content: this.metaDescription?.replace(/<a\b[^>]*>(.*?)<\/a>/gi, ""),
        },
        {
          hid: "og:url",
          property: "og:url",
          content: "https://www.buro26.digital",
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          hid: "og:title",
          property: "og:title",
          // @ts-ignore
          content: this.Item.Title?.replace(/\*\*/g, ""),
        },
        {
          hid: "og:description",
          property: "og:description",
            // @ts-ignore
          content: this.metaDescription?.replace(/<a\b[^>]*>(.*?)<\/a>/gi, ""),
        },
        {
          hid: "og:image",
          property: "og:image",
          content: OgImage,
        }
      ],
    };
  },
  setup() {
    // Logic with hooks
  },
  components: {
    MetaDate: () => import("~/components/Meta/MetaDate.vue"),
    Socials: () => import("~/layouts/Socials.vue"),
    Button,
  },
  computed: {
    renderTitle(): string {
      const md = markdownIt({ html: true, breaks: true });
      // Parse markdown and render without <p> tags
       // @ts-ignore
      return md.renderInline(this?.Item?.Title);
    },
  },
  async asyncData({ route, $strapi, $config }): Promise<object | void> {
    const slug = getContentID(route);
    const response = await $strapi.graphql({
      query: `
        query {
          blogs (filters: { Slug: { eq: "` + slug + `"} } ) {
            data {
              id
              attributes {
                Title
                Slug
                Created
                Intro {
                  Text
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  Color
                }
                Content {
                  Text
                }
                Meta {
                  Author {
                    data {
                      attributes {
                        Title
                        Function
                        Mail
                        Image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    });

    const Item = await response.blogs.data[0].attributes;

    //replace img link by cdn
    Item.Content.Text = Item.Content.Text.replace(
      /\/uploads\//g,
      $config.cdnURL + "/uploads/"
    );

    //external link, add target=_blank
    const links = Item.Content.Text.match(/ \[([^\[]+)\]\((http.*?)\)/gm);
    if (links !== null && links.length > 0) {
      links.map((link: String) => {
        let href = link.match(/\((.*?)\)/);
        let title = link.match(/\[(.*?)\]/);
        if (href !== null && title !== null) {
          Item.Content.Text = Item.Content.Text.replace(
            link,
            ' <a href="' + href[1] + '" target="_blank">' + title[1] + "</a>"
          );
        }
      });
    }

    return { Item };
  },
});
