
import Vue from "vue" // @ts-ignore

export default Vue.extend({
  components: {
    Header: () => import("~/components/Header.vue"),
    Sidebar: () => import("~/components/Sidebar.vue"),
    Footer: () => import("~/components/Footer.vue"),
  },
  mounted() {
    this.correctVW();
    window.addEventListener("resize", this.correctVW);
  },
  methods: {
    correctVW() {
      //scrollbar width correction for use in css
      const body = document.querySelector("body");
      if (body) {
        const vw = body.clientWidth / 100;
        body.setAttribute("style", `--vw: ${vw}px`);
      }
    },
  },
});
