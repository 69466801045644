
import Vue from "vue";

export default Vue.extend({
  name: "",
  props: {
    link: {
      type: String,
      required: true,
    },
    itemClass: {
      type: String,
      required: true,
    },
    targetBlank: {
      type: Boolean,
      required: true,
    },
    colorTheme: String,
    size: String,
    useLabel: Boolean,
    label: String,
  },
});
