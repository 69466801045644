import Vue from 'vue'
import Router from 'vue-router'
import StaticRoutes from 'static/routes.json';

import PageIndex from '~/pages/page/index';
import BlogDetail from '~/pages/blog/_id.vue';
import CasesDetail from '~/pages/cases/_id.vue';

Vue.use(Router);

export async function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions ? routerOptions : createDefaultRouter(ssrContext).options;

  return new Router({
    ...options,
    routes: fixRoutes(options.routes)
  });
}

function fixRoutes(defaultRoutes) {
  const routes = [];

  StaticRoutes.map(el => {
    const name = el.name;
    const path = el.path;
    const component = getComponent(el.component);

    const route = {name: name, path: path, component: component};

    routes.push(route);
  });

  return routes;
}

function getComponent(name) {
  switch (name) {
    case "PageIndex":
      return PageIndex;
    case "BlogDetail":
      return BlogDetail;
    case "CasesDetail":
      return CasesDetail;
  }
}
