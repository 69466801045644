
import Vue from "vue"; // @ts-ignore

export default Vue.extend({
  data() {
    return {
      homepage: false,
    };
  },
  components: {
    Logo: () => import("~/components/Logo.vue"),
  },
});
